<template>
  <v-container fluid>
    <v-card flat>
      <v-sheet class="pa-2 mb-3" color="primary lighten-5">
        <v-row align="center" justify="center">
          <v-col cols="12" lg="3">
            {{
              fileType === "resit"
                ? `${script.semester.semester} Level ${script.level.level}`
                : `${script.calendar.academic_calendar} ${script.semester.semester} Level ${script.level.level}`
            }}
          </v-col>
          <v-col cols="12" lg="3">
            {{
              fileType === "resit"
                ? `${script.course_name}`
                : `${script.course.coursecode_coursename} `
            }}
          </v-col>
          <v-col cols="12" lg="3">
            {{
              fileType === "resit"
                ? script.program_name
                : script.program.program_name_certificate
            }}
          </v-col>
          <v-col cols="12" lg="3" v-if="fileType !== 'resit'">
            {{ `${script.campus.campus_name}` }}
          </v-col>
        </v-row>
      </v-sheet>
      <v-row align="start" justify="center">
        <v-col cols="12" lg="10">
          <v-file-input
            outlined
            class="rounded-0"
            chips
            show-size
            v-model="file"
            :rules="rules"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="Pick an avatar"
            label="Click to Select File to Upload"
          ></v-file-input>
        </v-col>
        <v-col v-if="file" cols="12" lg="2">
          <v-btn
            :loading="loading"
            @click="
              fileType === 'resit'
                ? sendResit()
                : fileType === 'retake'
                ? sendRetake()
                : sendFile()
            "
            x-large
            color="primary"
            class="rounded-0"
          >
            <v-icon>mdi-upload</v-icon>
            Upload
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="resultBasket.length > 0">
        <v-row justify="end">
          <v-col cols="3">
            <v-spacer></v-spacer>
            <v-text-field
              append-icon="mdi-magnify"
              label="Search"
              v-model="search"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="resultBasket"
              :search="search"
              hide-default-footer
              class="elevation-1"
              fixed-header
            >
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-container>
</template>
<script>
  import { defineComponent, inject, reactive, toRefs } from "vue";
  import Api from "../../apis/Api";
  export default defineComponent({
    setup() {
      const documentData = reactive({
        loading: false,
        files: [],
        uploader: null,
        uploadedFiles: [],
        title: "",
        emptyImg: false,
        dragover: false,
        transparent: "rgba(255, 255, 255, 0)",
        search: null,
        validType: [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ],
        rules: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2 MB!",
        ],
        file: null,
        resultBasket: [],
        headers: [
          { text: "INDEX NUMBER", value: "index_number" },
          { text: "NAME OF STUDENT", value: "fullname" },
          { text: "MID SEMESTER SCORE", value: "midsem", align: "center" },
          {
            text: "END OF SEMESTER SCORE",
            value: "exam",
            align: "center",
          },
          { text: "TOTAL SCORE", value: "total", align: "center" },
          { text: "GRADE", value: "grade", align: "center" },
          //{ text: "SUBMITTED", value: "remarks", align: "center" },
        ],
        fileType: "",
        script: "",
      });

      const { file, resultBasket, loading, script, fileType } =
        toRefs(documentData);

      const scriptFileUpload = inject("scriptFileUpload");

      script.value = scriptFileUpload.script.value;
      fileType.value = scriptFileUpload.scriptType;

      const sendFile = async () => {
        let formData = new FormData();
        formData.append("file", file.value);
        formData.append(
          "data",
          JSON.stringify({
            calendar_id: scriptFileUpload.value?.calendar?.id,
            campus_id: scriptFileUpload.value?.campus?.id,
            course_id: scriptFileUpload.value?.course?.id,
            level_id: scriptFileUpload.value?.level?.id,
            semester_id: scriptFileUpload.value?.semester?.id,
            program_id: scriptFileUpload.value?.program?.id,
            grade_id: scriptFileUpload.value?.grade?.id,
            lecturer_id: scriptFileUpload.value?.lecturer?.id,
            intake_id: scriptFileUpload.value?.intake?.id,
          })
        );
        loading.value = true;
        await Api()
          .post("/uploadscript", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            file.value = null;

            resultBasket.value = res.data;
          })
          .catch((e) => {
            switch (e.response.status) {
              case 422:
                // msgBody.value = e.response.data.message;
                break;
              case 423:
                // msgBody.value = e.response.data.message;
                /* signOut().then(() => {
                                                                                                                                                      context.root.$router.replace({
                                                                                                                                                        name: "Login",
                                                                                                                                                      });
                                                                                                                                                    }); */
                break;
              default:
                // msgBody.value =
                // "Excel File does not Match the Original Excel File Downloaded";
                break;
            }
          })
          .finally(() => (loading.value = false));
      };

      const sendResit = async () => {
        let formData = new FormData();
        formData.append("file", file.value);
        formData.append(
          "data",
          JSON.stringify({
            course_id: script.value?.course_id,
            program_id: script.value?.program_id,
            grade_id: script.value?.grade_id,
            lecturer_id: script.value?.user_id,
            id: script.value?.id,
          })
        );
        loading.value = true;
        await Api()
          .post("/uploadresitscript", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            file.value = null;

            resultBasket.value = res.data;
          })
          .catch((e) => {
            switch (e.response.status) {
              case 422:
                // msgBody.value = e.response.data.message;
                break;
              case 423:
                // msgBody.value = e.response.data.message;
                /* signOut().then(() => {
                                                                                                                                                      context.root.$router.replace({
                                                                                                                                                        name: "Login",
                                                                                                                                                      });
                                                                                                                                                    }); */
                break;
              default:
                // msgBody.value =
                // "Excel File does not Match the Original Excel File Downloaded";
                break;
            }
          })
          .finally(() => (loading.value = false));
      };

      const sendRetake = () => {
        console.log("first");
      };

      return {
        sendFile,
        sendResit,
        ...toRefs(documentData),
        script,
        fileType,
        sendRetake,
      };
    },
  });
</script>
<style scoped>
  .v-card {
    transition: opacity 0.4s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.6;
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 0, 0, 0.25),
      rgba(255, 0, 0, 0.25) 5px,
      rgba(0, 0, 255, 0.25) 5px,
      rgba(0, 0, 255, 0.25) 10px
    );
  }
</style>  